<template>
    <div class="header-fix" :class="isActiveHeaderFix">
        <div class="header-fix__content info-container">

            <ButtonBack />

            <div class="header-fix__title">{{ article.name }}</div>
        </div>
        <div class="header-fix__bar">
            <div class="header-fix__progress" :style="{ width: percentScroll + '%'}"></div>
        </div>
    </div>

    <ButtonBack />

    <div class="section-grid section-grid--base">
        <div class="section-blog">
            <div class="section-blog__header">
                <div class="section-blog__title title-second text-blue">{{ article.name }}</div>
                <div class="text-gray">{{ article.date }}</div>
            </div>
            <div class="section-blog__content typography" v-html="article.description"></div>
        </div>

        <div class="sticky-wrapper">
            <div class="courses-list courses-list--primary">
                <CardBlog v-for="(item, index) in listArticles" :key="index" :article="item" />
            </div>
        </div>
    </div>
</template>

<script>
import store from "@/store/index.js"
import { ref, onUnmounted } from "vue"
import CardBlog from "@/pages/personal/components/card-blog"

export default {
    beforeRouteEnter(to, from, next) {
        store.dispatch("PERSONAL_GET_ITEM_ARTICL", to.params.slug).then(() => {
            next();
        });
    },
    beforeRouteUpdate(to, from, next) {
        store.dispatch("PERSONAL_GET_ITEM_ARTICL", to.params.slug).then(() => {
            next();
        });
    },

    components: {
        CardBlog,
    },

    computed: {
        article() {
            return this.$store.state.student.article
        },
        listArticles() {
            return this.$store.state.student.listArticles
        }
    },

    setup() {
        const isActiveHeaderFix = ref('')
        const percentScroll = ref(0)

        const handleScroll = () => {
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop

            if (scrollTop > 70) isActiveHeaderFix.value = 'active'
            else isActiveHeaderFix.value = ''

            percentScroll.value = scrollTop / (document.body.scrollHeight - window.innerHeight) * 100
        }

        window.addEventListener('scroll', handleScroll)

        onUnmounted(() => window.removeEventListener('scroll', handleScroll))

        return { isActiveHeaderFix, percentScroll }
    },
}
</script>
